
import { KAPLAYCtx, GameObj, CharTransformFunc, CharTransform } from "kaplay";
import { COLORS } from "./colors";

export const getDefaultTextStyles = (k: KAPLAYCtx) => {
  const ret: Record<string, CharTransform | CharTransformFunc> = {
    'p': {
      color: k.BLACK
    },
    'note': () => ({
      color: k.BLACK,
      opacity: k.wave(0, 1, k.time() * 6)
    })
  }
  return ret
}

const MIN_DIALOG_HEIGHT = 192

let writingLoop
let writing = false

function startWriting(k: KAPLAYCtx, txt: GameObj) {
  writingLoop?.cancel()
  writing = true

  txt.letterCount = 0;
  writingLoop = k.loop(0.05, () => {
    txt.letterCount = Math.min(
      txt.letterCount + 1,
      txt.renderedText.length,
    );
    k.play("bean_voice", {
      volume: 0.2,
    });
    if (txt.letterCount === txt.renderedText.length) {
      writingLoop.cancel();
      writing = false
    }
  });
}

const dissmissCallbacks = new Set<() => void>()

export function addDialog(k: KAPLAYCtx) {
  const pad = 16;
  const bg = k.add([
    k.pos(0, 0),
    k.rect(k.width(), k.height()),
    k.color(COLORS.background),
    k.z(100),
    k.animate(),
  ]);
  const char = k.add([
    k.sprite("bean"),
    k.pos(k.center().add(0, -128)),
    k.anchor('center'),
    k.scale(3),
    k.z(100),
    k.animate(),
  ])
  const textbox = k.add([
    k.rect(k.width() - 140, MIN_DIALOG_HEIGHT, { radius: 8 }),
    k.anchor("center"),
    k.pos(k.center().x, k.height() - 480),
    k.z(100),
    k.animate(),
    k.outline(4),
  ]);
  const txt = k.add([
    k.text("", {
      width: textbox.width - pad,
      size: 36,
      lineSpacing: 8,
      styles: getDefaultTextStyles(k),
      transform: (idx, ch) => {
        return {
          opacity: idx < txt.letterCount ? 1 : 0,
        };
      },
    }),
    k.anchor('center'),
    k.pos(textbox.pos.add(pad, 0)),
    k.z(100),
    k.animate(),
    {
      letterCount: 0
    }
  ]);
  textbox.hidden = true;
  char.hidden = true;
  bg.hidden = true;
  txt.hidden = true;
  return {
    say(text: string, character?: string, onDismiss?: () => void) {
      k.camScale(1)
      k.camPos(k.width() / 2, k.height() / 2)
      if (character) {
        char.sprite = character
        char.hidden = false;
      }
      txt.text = text + "\n\n[note] 点击关闭 [/note]";
      textbox.height = Math.max(txt.height + 64, MIN_DIALOG_HEIGHT)
      textbox.pos = char.pos.add(0, textbox.height / 2 + 128)
      txt.pos = textbox.pos.add(pad, 0)
      textbox.hidden = false;
      bg.hidden = false;
      txt.hidden = false;
      // animFadeIn(k, [char, textbox, txt, bg], 'in')
      startWriting(k, txt)
      if (onDismiss) {
        dissmissCallbacks.add(onDismiss)
      }
    },
    dismiss() {
      if (!this.active()) {
        return;
      }
      if (writing) {
        txt.letterCount = txt.renderedText.length
        return;
      }
      txt.text = "";
      textbox.hidden = true;
      bg.hidden = true;
      txt.hidden = true;
      char.hidden = true;
      // dissmiss cbs
      dissmissCallbacks.forEach(it => {
        it()
      })
      dissmissCallbacks.clear()
    },
    active() {
      return !bg.hidden;
    },
    destroy() {
      textbox.destroy();
      bg.destroy();
      txt.destroy();
      char.destroy();
    },
  };
}