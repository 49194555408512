import { crossFadeGo, setVhs } from "@/shaders";
import { playBgm } from "@/sound";
import { addDialog } from "@/text";
import { Scene } from "@/utils";
import { GameObj, KAPLAYCtx } from "kaplay";

const limit = (val: number, abs: number) => {
  abs = Math.abs(abs)
  return Math.max(Math.min(val, abs), -abs)
}


const TILE_WIDTH = 64;
const TILE_HEIGHT = TILE_WIDTH;

function createMazeMap(width, height) {
  const size = width * height;
  function getUnvisitedNeighbours(map, index) {
    const n = [];
    const x = Math.floor(index / width);
    if (x > 1 && map[index - 2] === 2) n.push(index - 2);
    if (x < width - 2 && map[index + 2] === 2) n.push(index + 2);
    if (index >= 2 * width && map[index - 2 * width] === 2) {
      n.push(index - 2 * width);
    }
    if (index < size - 2 * width && map[index + 2 * width] === 2) {
      n.push(index + 2 * width);
    }
    return n;
  }
  const map = new Array(size).fill(1, 0, size);
  map.forEach((_, index) => {
    const x = Math.floor(index / width);
    const y = Math.floor(index % width);
    if ((x & 1) === 1 && (y & 1) === 1) {
      map[index] = 2;
    }
  });

  const stack = [];
  const startX = Math.floor(Math.random() * (width - 1)) | 1;
  const startY = Math.floor(Math.random() * (height - 1)) | 1;
  const start = startX + startY * width;
  map[start] = 0;
  stack.push(start);
  while (stack.length) {
    const index = stack.pop();
    const neighbours = getUnvisitedNeighbours(map, index);
    if (neighbours.length > 0) {
      stack.push(index);
      const neighbour =
        neighbours[Math.floor(neighbours.length * Math.random())];
      const between = (index + neighbour) / 2;
      map[neighbour] = 0;
      map[between] = 0;
      stack.push(neighbour);
    }
  }
  return map;
}

function createMazeLevelMap(width, height, options) {
  const symbols = options?.symbols || {};
  const map = createMazeMap(width, height);
  const space = symbols[" "] || " ";
  const fence = symbols["#"] || "#";
  const detail = [
    space,
    symbols["╸"] || "╸", //  1
    symbols["╹"] || "╹", //  2
    symbols["┛"] || "┛", //  3
    symbols["╺"] || "╺", //  4
    symbols["━"] || "━", //  5
    symbols["┗"] || "┗", //  6
    symbols["┻"] || "┻", //  7
    symbols["╻"] || "╻", //  8
    symbols["┓"] || "┓", //  9
    symbols["┃"] || "┃", //  a
    symbols["┫"] || "┫", //  b
    symbols["┏"] || "┏", //  c
    symbols["┳"] || "┳", //  d
    symbols["┣"] || "┣", //  e
    symbols["╋ "] || "╋ ", //  f
  ];
  const symbolMap = options?.detailed
    ? map.map((s, index) => {
      if (s === 0) return space;
      const x = Math.floor(index % width);
      const leftWall = x > 0 && map[index - 1] == 1 ? 1 : 0;
      const rightWall = x < width - 1 && map[index + 1] == 1 ? 4 : 0;
      const topWall = index >= width && map[index - width] == 1 ? 2 : 0;
      const bottomWall =
        index < height * width - width && map[index + width] == 1
          ? 8
          : 0;
      return detail[leftWall | rightWall | topWall | bottomWall];
    })
    : map.map((s) => {
      return s == 1 ? fence : space;
    });
  const levelMap = [];
  for (let i = 0; i < height; i++) {
    levelMap.push(symbolMap.slice(i * width, i * width + width).join(""));
  }
  return levelMap;
}

export const addGameScene = (k: KAPLAYCtx) => {
  k.scene(Scene.game, (levelIdx) => {
    playBgm(k)

    const SPEED = 256 * 10;

    // character dialog data
    const characters = {
      "a": {
        trigger: false,
        sprite: "bag",
        msg: "[p]Hi Y! 欢迎来到魔法的世界，你的爱人留下了一封信给你，找到🔑并打开🚪拿到信吧！\n\n开始摇晃手机，让角色移动吧！[/p]",
      },
      "b": {
        trigger: false,
        sprite: "ghosty",
        msg: "[p]走这里不对哦[/p]",
      },
      "c": {
        trigger: false,
        sprite: "bobo",
        msg: "[p]这里是死路哦[/p]",
      },
    };

    // level layouts
    const levels = [
      [
        "===========",
        "=  b     =",
        "=   =    =",
        "=    =   =",
        "=  ===   =",
        "=  =     =",
        "=  = $=  =",
        "=  ===  ==",
        "=        =",
        "=  =     =",
        "=  =     |",
        "=  ===   =",
        "=  =     =",
        "=  =     =",
        "= a=     =",
        "= @=     =",
        "==========="
      ],
      [
        "###############",
        "#@  #     # # #",
        "# # # ### # # #",
        "# #   # # #   #",
        "# ##### # # # #",
        "# #c# #   # # #",
        "# # # # ### # #",
        "#   # # #   # #",
        "##### # # ### #",
        "# #     #|#   #",
        "# # ####### # #",
        "#c          # #",
        "# # # # ### # #",
        "# # # # #$  # #",
        "###############"
      ],
      [
        "#################",
        "#@      # # # # #",
        "# ##### # # # # #",
        "#     #         #",
        "##### ######### #",
        "#   # #   #   # #",
        "# # # # # # # # #",
        "# # #   #   # #$#",
        "# # ### ##### ###",
        "# #   #    c#   #",
        "# ### ######### #",
        "#   #     #   # #",
        "### ##### # # # #",
        "#   #   #   #   #",
        "### # # # ### # #",
        "#c  #|#   #c  # #",
        "#################"
      ],
    ];

    const level = k.addLevel(levels[levelIdx], {
      tileWidth: 64,
      tileHeight: 64,
      // pos: TILE_POS,
      tiles: {
        "#": () => [
          k.sprite("grass"),
          k.area(),
          k.body({ isStatic: true }),
          k.anchor("center"),
        ],
        "=": () => [
          k.sprite("grass"),
          k.area(),
          k.body({ isStatic: true }),
          k.anchor("center"),
        ],
        "-": () => [
          k.sprite("steel"),
          k.area(),
          k.body({ isStatic: true }),
          k.anchor("center"),
        ],
        "$": () => [
          k.sprite("key"),
          k.area(),
          k.anchor("center"),
          "key",
        ],
        "@": () => [
          k.sprite("bean"),
          // @ts-expect-error
          k.area({ friction: 0.2 }),
          k.body(),
          k.anchor("center"),
          k.agent({ speed: 640, allowDiagonals: true }),
          "player",
        ],
        "|": () => [
          k.sprite("door"),
          k.area(),
          k.body({ isStatic: true }),
          k.anchor("center"),
          "door",
        ],
      },
      // any() is a special function that gets called everytime there's a
      // symbole not defined above and is supposed to return what that symbol
      // means
      wildcardTile(ch) {
        const char = characters[ch];
        if (char) {
          return [
            k.sprite(char.sprite),
            k.area(),
            k.body({ isStatic: true }),
            k.anchor("center"),
            "character",
            "char_" + char,
            { msg: char.msg, msg_trigger: false },
          ];
        }
      },
    });

    // get the player game obj by tag
    const player: GameObj = level.get("player")[0];

    if (import.meta.env.DEV) {
      const dirs = {
        "left": k.LEFT,
        "right": k.RIGHT,
        "up": k.UP,
        "down": k.DOWN,
      };

      for (const dir in dirs) {
        k.onKeyPress(dir, () => {
          dialog.dismiss();
        });
        k.onKeyDown(dir, () => {
          player.move(dirs[dir].scale(SPEED));
        });
      }
    }

    let hasKey = false;
    const dialog = addDialog(k);

    player.onCollide("key", (key) => {
      k.destroy(key);
      k.play("pickup_coin")
      hasKey = true;
    });

    player.onCollide("door", () => {
      if (hasKey) {
        if (levelIdx + 1 < levels.length) {
          dialog.say("[p]太棒了，Y！请继续寻找💌吧！[/p]", "heart", () => {
            crossFadeGo(k, Scene.game, levelIdx + 1)
          })
        }
        else {
          k.play("win")
          crossFadeGo(k, Scene.win)
        }
      }
      else {
        dialog.say("[p]你还没找到钥匙🔑呢！[/p]", "door");
      }
    });

    if (levelIdx === 0) {
      const a = characters.a
      dialog.say(a.msg, a.sprite)
    }

    // talk on touch
    player.onCollide("character", (ch) => {
      if (!ch.msg_trigger) {
        dialog.say(ch.msg, ch.sprite);
        ch.msg_trigger = true
      }
    });

    k.onTouchStart(() => {
      dialog.dismiss()
    })

    const deviceOrientation = {
      beta: 0,
      gamma: 0
    }
    window.addEventListener('deviceorientation', (event: DeviceOrientationEvent) => {
      let beta = event.beta; // 上下倾斜（前后滚动）角度
      let gamma = event.gamma; // 左右倾斜（左右滚动）角度
      deviceOrientation.beta = beta
      deviceOrientation.gamma = gamma
    });

    const CAM_SCALE = import.meta.env.DEV ? 0.5 : 2.5;

    k.onUpdate(() => {
      if (!dialog.active()) {
        const playerToPos = k.vec2(
          limit(deviceOrientation.gamma, 10),
          limit(deviceOrientation.beta, 10)
        ).scale(40)
        player.move(playerToPos);
        k.camScale(CAM_SCALE)
        k.camPos(player.worldPos());
      }
    });

    player.onPhysicsResolve(() => {
      if (!dialog.active()) {
        k.camScale(CAM_SCALE)
        k.camPos(player.worldPos());
      }
    });
  });
}