import kaplay from "kaplay";
import { loadShaders, setVhs } from "./shaders";
import fusionPixel from './assets/fonts/fusion-pixel-10px-monospaced-zh_hans.ttf'
import fusionPixelDev from '../scripts/fusion-pixel-10px-monospaced-zh_hans.ttf'
import { Scene } from "./utils";
import { COLORS } from "./colors";
import { addWinScene } from "./scenes/win";
import { addMenuScene } from "./scenes/menu";
import { addGameScene } from "./scenes/game";

export const mainGame = () => {

  const fontUrl = import.meta.env.DEV ? fusionPixelDev : fusionPixel

  const k = kaplay({
    background: COLORS.background,
    font: "FusionPixel",
    crisp: true,
    scale: 1.5,
    debug: import.meta.env.DEV,
  });

  k.onLoading((progress) => {
    // Black background
    k.drawRect({
      width: k.width(),
      height: k.height(),
      color: k.Color.fromHex(COLORS.background),
    });

    // A pie representing current load progress
    k.drawCircle({
      pos: k.center(),
      radius: 32,
      end: k.map(progress, 0, 1, 0, 360),
    });

    k.drawText({
      text: "加载中" + ".".repeat(k.wave(1, 4, k.time() * 12)),
      size: 24,
      anchor: "center",
      pos: k.center().add(0, 70),
    });
  });

  k.loadFont("FusionPixel", fontUrl)

  loadShaders(k)

  k.loadSprite("bag", "/sprites/bag.png");
  k.loadSprite("ghosty", "/sprites/ghosty.png");
  k.loadSprite("grass", "/sprites/grass.png");
  k.loadSprite("steel", "/sprites/steel.png");
  k.loadSprite("door", "/sprites/door.png");
  k.loadSprite("key", "/sprites/key.png");
  k.loadSprite("bean", "/sprites/bean.png");
  k.loadSprite("steel", "/sprites/steel.png");
  k.loadSprite("bobo", "/sprites/bobo.png");
  k.loadSprite("heart", "/sprites/heart.png");

  k.loadSound("bean_voice", "/sounds/bean_voice.wav");
  k.loadSound("pickup_coin", "/sounds/pickup_coin.wav");
  k.loadSound("win", "/sounds/gamestart-272829.mp3");
  k.loadSound("bgm02", "/sounds/bgm02.mp3");

  addMenuScene(k)
  addGameScene(k)
  addWinScene(k)

  setVhs(k)
  k.go(Scene.menu, 0);
}