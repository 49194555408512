import { crossFadeGo } from "@/shaders";
import { getDefaultTextStyles } from "@/text";
import { Scene } from "@/utils";
import { KAPLAYCtx } from "kaplay";

export const addMenuScene = (k: KAPLAYCtx) => {
  k.scene(Scene.menu, () => {
    k.add([
      k.sprite('bean'),
      k.pos(k.width() / 2, k.height() / 2),
      k.scale(4),
      k.anchor('center')
    ])

    k.add([
      k.text("[p]Letter\nto\nY![/p]", {
        letterSpacing: 1,
        lineSpacing: 8,
        size: 128,
        styles: getDefaultTextStyles(k),
        transform: (idx) => ({
          pos: k.vec2(0, k.wave(-4, 4, k.time() * 4 + idx * 0.5)),
          scale: k.wave(1, 1.2, k.time() * 3 + idx),
        })
      }),
      k.pos(k.width() / 2, k.height() / 5),
      k.anchor('center'),
    ])

    k.add([
      k.text("[p]点击开始[/p]", {
        letterSpacing: 1,
        size: 48,
        styles: getDefaultTextStyles(k),
        transform: () => ({
          opacity: k.wave(0, 1, k.time() * 6)
        })
      }),
      k.pos(k.width() / 2, k.height() - 256),
      k.anchor('center'),
    ])

    const toMain = () => {
      crossFadeGo(k, Scene.game, 0)
    }
    k.onClick(() => {
      toMain()
    })

    k.onKeyPress(() => {
      toMain()
    })
  })
}