import { KAPLAYCtx } from 'kaplay'
import { Scene } from './utils'

export const loadShaders = (k: KAPLAYCtx) => {
  k.loadShaderURL('fade', null, '/fade.frag')
  k.loadShaderURL('vhs', null, '/vhs.frag')
}

const VHS_INTENSITY = 8
export const FADE_DURATION = 0.6

export const setVhs = (k: KAPLAYCtx) => {
  k.usePostEffect('vhs', {
    'vhsIntensity': VHS_INTENSITY
  })
}

export const crossFadeGo = (k: KAPLAYCtx, name: Scene, ...args: any) => {
  fade(k, 'in')
  k.go(name, ...args)
}

export const fade = (k: KAPLAYCtx, type: 'in' | 'out') => {
  const startTime = k.time();
  k.usePostEffect("fade", () => ({
    "fadeIn": type === 'in' ? 1 : 0,
    "startTime": startTime,
    "time": k.time(),
    "duration": FADE_DURATION,
    'vhsIntensity': VHS_INTENSITY
  }))
}