import { crossFadeGo } from "@/shaders";
import { getDefaultTextStyles } from "@/text";
import { Scene } from "@/utils";
import { KAPLAYCtx } from "kaplay";

export const addWinScene = (k: KAPLAYCtx) => {
  k.scene(Scene.win, () => {
    const textbox = k.add([
      k.rect(k.width() - 96, 888, { radius: 8 }),
      k.anchor("center"),
      k.pos(k.center().add(0, -128)),
      k.z(100),
      k.animate(),
      k.outline(4),
    ])
    const text = k.add([
      k.text("亲爱的Y：\n\n当你找到这封信的时候我已经死了，我们已经相处了一辈子，与你相处的每一刻时光都十分美好，我感到非常幸福，人生已经没有缺憾了。\n\n你永远都那么活泼动人，你是我黯淡人生里仅有的一抹光，世界正在分崩离析，我也已经离开这个世界，我永远怀念与你相处的日子，我永远爱你，再见了。\n\n不过如果能找到时光机的话，说不定一切都能重来……\n\n20xx年1月1日", {
        width: k.width() / 1.3,
        letterSpacing: 2,
        lineSpacing: 8,
      }),
      k.pos(k.width() / 2, k.height() / 2 - 128),
      k.anchor("center"),
      k.z(100),
      k.color(50, 50, 50),
    ]);
    const back = k.add([
      k.rect(128, 80, { radius: 8 }),
      k.pos(k.width() / 2, k.height() - 156),
      k.area(),
      k.scale(1),
      k.anchor("center"),
      k.outline(4),
      k.color(255, 255, 255),
    ]);
    back.add([
      k.text('重来'),
      k.anchor("center"),
      k.color(0, 0, 0),
    ]);
    back.onClick(() => {
      crossFadeGo(k, Scene.game, 0)
    })
  });
}