import { AudioPlay, KAPLAYCtx } from "kaplay";

let bgmPlay: AudioPlay | undefined

export const playBgm = (k: KAPLAYCtx) => {
  if (import.meta.env.DEV) {
    return
  }
  if (!bgmPlay) {
    bgmPlay = k.play("bgm02", {
      loop: true
    })
  }
}